const text = {
  GENERAL_INFO: 'General info',
  PRACTICE_DETAILS: 'Practice details',
  FINISH: 'Finish',
  BOOK_APPOINTMENT: 'Book an appointment',
  ACCESS_IMAGES: 'Access patient images',
  MENU: 'menu',
  SUCCESS: 'success',
  CLOSE: 'close',
  VIEW_SITE_AS: 'View site as:',
  PATIENT: 'Patient',
  PRACTITIONER: 'Practitioner',
  FOOTER_COPYRIGHT: 'Copyright © I-MED Radiology Network 2021',
  RELATED_ARTICLES: 'Related articles',
  SERVICES_AVAILABLE: 'Services available',
  PARKING: 'Parking',
  PUBLIC_TRANSPORT: 'Public transport',
  OPENING_HOURS: 'Opening hours',
  PRINT_PAGE: 'Print page',
  READ_MORE: 'Read more',
  READ_MORE_ABOUT: 'Read more about',
  BOOK_APPOINTMENT_TITLE: 'I-MED Radiology | Book an appointment',
  META_TITLE_PREFIX: 'I-MED Radiology Network',
  BOOK_APPOINTMENT_DESCRIPTION: 'You can easily book a radiology appointment with I-MED',
  FIND_CLINIC_IFRAME_TITLE: 'Clinic map',
  OR: 'or',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
  PUBLICATIONS: 'Publications',
  ABOUT: 'About ',
  YES: 'Yes, ',
  SUBMIT: 'Submit',
  RELATED_PROCEDURES: 'Related procedures',
  PREVIEW_MESSAGE: 'This page is in preview mode. The changes are visible only to you. ',
  CLICK_HERE: 'Click here',
  RE_ENTER_DETAILS: 'Re-enter details',
  TO_EXIT_PREVIEW: ' to exit preview mode.',
  OPEN_FORM_NEW_WINDOW: ' If the form is not loaded, please click here to open in a new window.',
  SHOW_MORE_SPECLIALISTS: 'Show more specialists',
  CHOOSE: 'Choose..',
  CLINIC_DETAILS: 'Clinic details',
  REGION: 'Region',
  FULL_NAME: 'Full name',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL: 'Email address',
  CONTACT_NUMBER: 'Contact number',
  ADDITIONAL_OPTIONS: 'Additional options',
  EMAIL_OPTIONAL: 'Email address (optional)',
  MOBILE_NUMBER: 'Mobile number',
  AHPRA: 'AHPRA number',
  CHECK_EMAIL: 'Check your email',
  DIDNT_RECEIVE: 'Didn’t receive an email?',
  DIDNT_RECEIVE_EMAIL_SMS: 'Didn’t receive an email or SMS?',
  EMAIL_SENT: 'We’ve sent an email to ',
  WITH_USERNAME: ' with your username.',
  WITH_PASSWORD_RESET: ' with password reset instructions.',
  RECOVER_PASSWORD_PROMPT:
    'If you don’t see an email from us within a few minutes, check your spam folder or contact the Referrer Success Team on 1300 147 852.',
  RECOVER_USERNAME_PROMPT:
    'If you don’t see an email from us within a few minutes, check your spam folder or click below to re-enter\n' +
    '            your email address and AHPRA number to try again.',
  LOGOUT: 'Logout',
  BACK_TO_WEBSITE: 'Back to website',
  RECOVER_USER_ID: 'Recover your username',
  SIGN_IN_TO_IMOL: 'Sign in to I-MED Online',
  SIGN_IN: 'Sign in',
  REGISTER_ERROR_EMAIL: 'Email address is either taken or not formatted correctly.',
  REGISTER_ERROR_AHPRA: 'AHPRA number is either taken or does not exist.',
  AHPRA_ERROR_MESSAGE: 'Please enter a valid AHPRA number',
  REGISTER_ERROR_USERNAME: 'Username is either taken or does not exist.',
  REGISTER_LABEL_USERNAME: 'I-MED Online / MIA username',
  REGISTER: 'Register for access to I-MED clinical imaging',
  REGISTER_SUCCESS: 'You will receive an email once your registration has been approved.',
  REGISTER_DID_NOT_RECEIVE: 'Didn’t receive an email?',
  REGISTER_CHECK_SPAM: 'If you don’t see an email from us within 1-2 business days, check your spam folder.',
  REGISTER_IMPERATIVE: 'All fields must be completed before continuing.',
  REGISTER_EMAIL_SENT_TO: 'The email will be sent to ',
  REGISTER_EMAIL_FURTHER_INSTRUCTIONS:
    ' and will include your username with instructions to log in. One of our team members may contact you if more details are required.',
  REGISTER_BACK_TO_SIGNIN: 'Back to sign in',
  USER_AGREEMENT_NOTICE: 'Please read and accept the terms below to complete your registration.',
  REGISTER_THESE_CREDENTIALS:
    'These credentials can be used to access I-MED Imaging via I-MED Online, InteleConnect and Inteleviewer once the registration has been approved.',
  USER_ID: 'Username',
  PASSWORD: 'Password',
  RECOVER_USER_ID_FAILURE: 'Please check your email address and AHPRA number are correct, then try again.',
  CONTINUE: 'Continue',
  BACK: 'Back',
  LOGIN_ERROR: 'The username or password is not correct',
  LOGOUT_SESSION_TIMEOUT: 'Your session has timed out, please sign in again',
  CURRENT_PASSWORD: 'Current password',
  NEW_PASSWORD: 'New password',
  CREATE_ACCOUNT: 'create an account',
  EMAIL_ADDRESS: 'Email address',
  RECOVER_BUTTON: 'Recover username',
  RETURN_TO_SIGNIN: 'return to sign in',
  AHPRA_WARNING: "Don't include spaces",
  FULL_NAME_INSTRUCTION: 'As per AHPRA/Medicare registration',
  REFERRER_EMAIL_INSTRUCTION: 'Cannot be generic or shared',
  NEED_SUPPORT: 'Need support? Call us on 1300 147 852',
  RECOVER_INFO:
    'You can recover your I-MED Online username using the email address and AHPRA number associated with your account.',
  FORGOT_ID: 'Forgotten your username?',
  FORGOT_PASSWORD: 'Forgotten your password?',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  REGISTER_FOR_ACCESS: 'Register for access',
  WELCOME_TO_IMOL: 'Welcome to I-MED Online',
  ACCOUNT_DETAILS: 'My Account',
  PROFILE: 'Your profile',
  CONTACT_INFO: 'Contact info',
  CHANGE_PASSWORD: 'Change password',
  UPDATE_CONTACT: 'Update contact info',
  PRACTICES: 'Your listed practices',
  ADD_ANOTHER_PRACTICE: 'Add another practice',
  ORDER_REFERRAL_PADS: 'Order referral pads',
  CLICK_TO_ORDER: 'Click here to order',
  ADD_ANOTHER_DOCTOR: 'Add another doctor',
  REMOVE_DOCTOR: 'Remove doctor',
  PRACTICE_NAME_PLACEHOLDER: 'e.g. Barton medical center',
  PRACTICE_NUMBER_PLACEHOLDER: 'e.g. 123456AB',
  PRACTICE_PHONE_PLACEHOLDER: 'e.g. 02 43211234',
  AHPRA_NUMBER_PLACEHOLDER: 'e.g. MED000012345',
  ADD_PRACTICE: 'Request practice',
  REQUEST_ANOTHER_PRACTICE: 'Request to add another practice',
  ADD_PRACTICE_SUBHEADER: 'To help protect patient data, I-MED will review your request to add another practice.',
  ADD_PRACTICE_FAIL: 'Failed to add practice. Please try again.',
  ADD_PRACTICE_SUCCESS:
    'Your request to add a practice was successful. It can take up to 5 business days to complete the process.',
  REMOVE_PRACTICE: 'Remove practice',
  PRACTICE_NAME: 'Practice name',
  PRACTICE_TYPE: 'Practice type',
  PRACTICE_EMAIL_OPTIONAL: 'Practice email address (optional)',
  PRACTICE_ADDRESS: 'Practice address',
  SPECIALITY_LABEL: 'Speciality (if any)',
  PROVIDER_NUMBER: 'Provider number',
  LOGIN_DETAILS: 'Login details',
  LOGIN_USER_CREDENTIALS_MESSAGE:
    'You can access the new referrer portal using your existing login credentials for I-MED Online 2.0',
  CHANGE_PASSWORD_ERROR: 'Failed to change password. Please check that the current password you provided is correct',
  CHANGE_PASSWORD_SUCCESS: 'Your password was successfully updated',
  UPDATE_CONTACT_INFO_SUCCESS: 'Your contact info has been updated',
  UPDATE_CONTACT_INFO_FAIL: 'Your contact information could not be updated',
  UPDATE_CONTACT_INFO_EMAIL_FAIL: 'The email is incorrect',
  RECOVER_USER_PASSWORD: 'Reset your password',
  RECOVER_USER_PASSWORD_BUTTON: 'Reset password',
  RECOVER_USER_PASSWORD_FAILURE: 'Please check your entry and try again',
  RECOVER_USER_PASSWORD_INFO:
    'We will send an SMS verification code to the mobile number associated with your account. You will also receive an email with instructions on how to reset your password.',
  LOADING_PART1: 'Page content is loading.',
  UNSUPPORTED_BROWSER_TITLE: 'Unsupported Browser',
  UNSUPPORTED_BROWSER_MESSAGE:
    'Internet Explorer is not supported. Please use one of the supported browsers listed below:',
  CHROME: 'Chrome',
  EDGE: 'Edge',
  FIREFOX: 'Firefox',
  SAFARI: 'Safari',
  CODE_REQUIRED: 'Verification code required',
  CODE_REQUIRED_PROMPT: 'You will need the verification code sent to your listed mobile number to continue.',
  VERIFICATION_CODE: 'Verification code',
  PASSWORD_REQUIREMENT_NUMBER: 'One number',
  PASSWORD_REQUIREMENT_SYMBOL: 'One symbol',
  PASSWORD_REQUIREMENT_LENGTH: '8 characters minimum',
  PASSWORD_RESET_UPDATED: 'Password updated',
  PASSWORD_RESET_SUCCESS_MSG: 'Your password has been successfully updated.',
  FORGOT_PASSWORD_SUCCESS_MESSAGE:
    'Your request to reset your password has been submitted. Next you will receive a SMS, that provides a passcode, along with an email where you submit this passcode.\n\n' +
    '     1. Click the link provided in the email\n' +
    '     2. Enter the passcode given in the SMS\n' +
    '     3. Create your new secure password\n\n' +
    '*The SMS and email will be sent to the details provided upon account registration.',
  PASSWORD_RESET_SUCCESS_BUTTON: 'Return to sign in',
  PASSWORD_INVALID_MSG: 'Password must include requirements below',
  CODE_INVALID_MSG: 'The code must have a correct format',
  PATIENT_SEARCH: 'Patient Search',
  PATIENT_SEARCH_ERROR_MESSAGE: 'Full name and DOB are required to see results. Check your date format is ',
  SEARCH: 'Search',
  APPOINTMENT_SEARCH: 'Upcoming Appointments',
  APPOINTMENT_STATUS_UPCOMING: 'Upcoming',
  PATIENT_SEARCH_EMPTY_STATE_BREAK_GLASS: 'Enter Full name and DOB to see results',
  SEARCH_NO_RESULTS_TITLE: 'No results found',
  SEARCH_NO_RESULTS_SUBTITLE: 'Try the following:',
  SEARCH_NO_RESULTS_CHECK_NAME_DOB: 'Check full name and DOB are correct',
  SEARCH_NO_RESULTS_ADJUST_FILTERS: 'Adjust your search filters to include more results',
  SEARCH_NO_RESULTS_CHECK_DATE: 'Check your date format is ',
  SEARCH_NO_RESULTS_CHECK_NET: 'Check your network connection',
  SEARCH_NO_RESULTS_CHECK_PROVIDER_NUMBER: 'Please check your provider number',
  PATIENT_SEARCH_SAVE_FILTERS_AS_DEFAULT_TOOLTIP: 'Saved filters will be applied by default for every new search',
  DROPDOWN_SELECT: 'Select',
  HIDDEN_PASSWORD: '********',
  FIELD_REQUIRED: 'This field is required',
  FULL_NAME_ERROR_MESSAGE: 'Please enter both first and last names',
  EMAIL_ERROR_MESSAGE: 'Please enter a valid email',
  USERNAME_ERROR_MESSAGE: 'Please enter a valid username',
  MOBILE_NUMBER_ERROR_MESSAGE: 'Please enter a valid mobile',
  PROVIDER_NUMBER_ERROR_MESSAGE: 'Please enter a valid provider number',
  PHONE_NUMBER_ERROR_MESSAGE: 'Please enter a valid phone number',
  DOB_ERROR_MESSAGE: 'Please enter a valid DOB',
  WEBCOMRAD_SUCCESS_MESSAGE_1: 'You have successfully submitted the registration to Webcomrad.',
  WEBCOMRAD_PASSWORD_RESET_SUCCESS_MESSAGE_1: 'You have successfully submitted the password reset.',
  WEBCOMRAD_SUCCESS_MESSAGE_2: 'Someone will be in contact with you shortly.',
  SUBMITTING_FORM_MESSAGE: 'The form is currently submitting',
  I_ACCEPT: 'I accept the ',
  TERMS_OF_USE: 'Terms of Use',
  WEBCOMRAD_T_AND_CS_MESSAGE:
    'By registering for an I-MED Online account, I agree to the terms of the User Access Agreement : ',
  USER_ACCESS_AGREEMENT_LINK: 'https://i-med.com.au/user-access-agreement',
  USER_ACCESS_AGREEMENT: 'User Access Agreement',
  OF_IMED: ' of the I-MED Radiology Network',
  FORM_SUBMIT_FAIL: 'Failed to process the form. It may include invalid inputs.',
  SUBMIT_PROCESSING: 'Processing the form...',
  SEARCH_DATE_RANGE_ALL_TIME: 'All time',
  SEARCH_DATE_RANGE_TODAY: 'Today',
  SEARCH_DATE_RANGE_YESTERDAY: 'Yesterday',
  SEARCH_DATE_RANGE_TOMORROW: 'Tomorrow',
  SEARCH_DATE_RANGE_PAST_WEEK: 'Past week',
  SEARCH_DATE_RANGE_NEXT_WEEK: 'Next week',
  SEARCH_DATE_RANGE_PAST_2_WEEKS: 'Past 2 weeks',
  SEARCH_DATE_RANGE_NEXT_2_WEEKS: 'Next 2 weeks',
  SEARCH_DATE_RANGE_PAST_MONTH: 'Past month',
  SEARCH_DATE_RANGE_NEXT_MONTH: 'Next month',
  SEARCH_DATE_RANGE_PAST_6_MONTHS: 'Past 6 months',
  SEARCH_DATE_RANGE_NEXT_6_MONTHS: 'Next 6 months',
  SEARCH_DATE_RANGE_PAST_YEAR: 'Past year',
  SEARCH_DATE_RANGE_NEXT_YEAR: 'Next year',
  SEARCH_DATE_RANGE_FILTERS: [
    'All time',
    'Today',
    'Yesterday',
    'Past week',
    'Past 2 weeks',
    'Past month',
    'Past 6 months',
    'Past year',
  ],
  APPOINTMENT_SEARCH_DATE_RANGE_FILTERS: [
    'Today',
    'Tomorrow',
    'Next week',
    'Next 2 weeks',
    'Next month',
    'Next 6 months',
    'Next year',
  ],
  ALL_PRACTICES: 'All listed practices',
  SEARCH_MODALITY_FILTERS: [
    'All modalities',
    'CR',
    'CT',
    'Mammography',
    'MRI',
    'Nuclear Medicine',
    'PET',
    'Ultrasound',
  ],
  ALL_MODALITIES: 'All modalities',
  PATIENT_DETAILS: 'Patient details',
  MAMMOGRAPHY: 'Mammography',
  NUCLEAR_MEDICINE: 'Nuclear Medicine',
  ULTRASOUND: 'Ultrasound',
  PLAIN_XRAY: 'Plain X-Ray',
  REPORT: 'Report',
  REPORT_PENDING_MESSAGE: 'Report is in progress and will be displayed here when ready.',
  STUDY_FOR: 'Study for ',
  DOB: 'DOB',
  GENDER: 'Gender',
  GENDER_OPTIONAL: 'Gender (optional)',
  CLEAR_ALL: 'Clear all',
  RESET: 'Reset',
  SAVE_FILTERS_AS_DEFAULT: 'Save filters as default',
  DEFAULT_FILTERS_UPDATED: 'Default filters updated',
  BOOK_AN_APPOINTMENT: 'Book an appointment',
  STATE: 'State',
  STATES: ['QLD', 'NSW', 'VIC', 'ACT', 'NT', 'WA', 'SA', 'TAS'],
  SPECIALITY_OPTIONS: [
    'General Practitioner',
    'General Practitioner Registrar',
    'General Practitioner Intern',
    'Nurse Practitioner',
    'Specialist',
    'Allied Health',
    'Hospital Registrar/Intern',
    'Dentist',
    'Other',
  ],
  OTHER_SPECIALITY_LABEL: 'Please specify the speciality',
  OTHER_SPECIALITY_ERROR_MESSAGE: 'Please enter a valid speciality',
  OPERATING_SYSTEM_MAC_LABEL: 'Mac',
  OPERATING_SYSTEM_PC_LABEL: 'PC Windows',
  OPERATING_SYSTEM_ERROR_MESSAGE: 'Please select an operating system',
  OPERATING_SYSTEM_LABEL: 'Operating system',
  YES_NO: ['Yes', 'No'],
  GENDER_OPTIONS: ['Male', 'Female'],
  POSTCODE: 'Postcode',
  SUBURB: 'Suburb',
  ADDRESS: 'Address',
  PRACTICE_PHONE: 'Practice phone number',
  PRACTICE_SUBURB: 'Practice suburb',
  PRACTICE_SUBURB_PLACEHOLDER: 'Sydney',
  PRACTICE_POSTCODE_PLACEHOLDER: '2000',
  WEBCOMRAD_REGISTRATION_FORM_TITLE: 'I-MED Online REGIONAL Access Registration',
  WEBCOMRAD_PASSWORD_RESET_TITLE: 'Password Reset',
  PRACTICE_FAX: 'Practice fax number (if applicable)',
  FAX_ERROR_MESSAGE: 'Please enter a valid fax number',
  DEPARTMENT_OR_WARD_LABEL: 'Department/ward (if applicable)',
  DEPARTMENT_ERROR_MESSAGE: 'Please enter a valid department',
  PATIENT_ID: 'Patient ID',
  SEARCH_REFERRED_WITHIN_MY_NETWORK: 'Referred within my network',
  SEARCH_REFERRED_BY_ANYONE: 'Referred by anyone',
  SEARCH_REFERRED_BY_ME: 'Referred by me',
  SEARCH_TYPE_ALL: 'all',
  SEARCH_TYPE_LINKED: 'linked',
  SEARCH_TYPE_REFERRER: 'referrer',
  STUDY_TABLE_STUDY_DATE: 'Study date',
  STUDY_TABLE_MODALITY: 'Modality',
  STUDY_TABLE_DESCRIPTION: 'Description',
  STUDY_TABLE_REFERRER: 'Referrer',
  STUDY_TABLE_ACCESSION_NO: 'Accession no.',
  STUDY_TABLE_IMAGES: 'Images',
  STUDY_TABLE_REPORT: 'Report',
  STUDY_TABLE_SHOW_IMAGES: 'View images',
  STUDY_TABLE_SHOW_REPORT: 'View report',
  STUDY_TABLE_IN_PROGRESS: 'In progress',
  PATIENT_REPORT_IMAGES_TITLE: 'Images',
  PATIENT_REPORT_DOCUMENTS_TITLE: (num: number): string => `Other documents (${num})`,
  PATIENT_REPORT_IMAGES_BUTTON: 'View images',
  PATIENT_REPORT_IMAGES_BACK_TO_RESULTS: 'Back to search results',
  PATIENT_REPORT_IMAGES_AVAILABLES1: ' image ',
  PATIENT_REPORT_IMAGES_AVAILABLES2: ' available for study',
  APPOINTMENT_TABLE_NEXT_APPOINTMENT: 'Next appointment',
  PROCEDURE_TABLE_PRACTICE: 'Location',
  PROCEDURE_TABLE_APPOINTMENT_DATE: 'Appt. date',
  PROCEDURE_TABLE_APPOINTMENT_TIME: 'Appt. time',
  PROCEDURE_TABLE_STATUS: 'Status',
  PATIENT_TABLE_NAME: 'Name',
  PATIENT_TABLE_LATEST_STUDY: 'Latest study',
  PATIENT_TABLE_TOTAL_STUDIES: 'Total studies',
  PENDING: 'Pending',
  APPPOINTMENT_PENDING: 'Appointment pending',
  STARTED: 'Started',
  PLACEHOLDER_DOB: 'DOB DD/MM/YYYY',
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_SEARCH_FORMAT: 'YYYY-MM-DD',
  PLACEHOLDER_FULL_NAME_OR_ID: 'Full name or Patient ID',
  PLACEHOLDER_NAME: 'e.g. Katie Bishop',
  PLACEHOLDER_PATIENT_NAME: 'e.g. John Smith',
  PLACEHOLDER_MOBILE: 'e.g. 0400112233',
  PLACEHOLDER_EMAIL: 'e.g. k.bishop@i-med.com',
  PLACEHOLDER_PATIENT_EMAIL: 'e.g. johnsmith@i-med.com',
  PRACTICE_ADDRESS_PLACEHOLDER: '123 Practice Street',
  WORKERS_COMPENSATION: "Does Worker's Compensation Apply?",
  VIEW_REFERRAL: 'View study referral',
  SEND_REFERRAL: 'Send e-referral',
  SEND_REGISTRATION: 'Send registration',
  REFERRAL_DESCRIPTION:
    'This referral will be sent to both I-MED Radiology and the patient will receive a text message.',
  REFERRAL_SENT: 'E-referral sent',
  REGISTRATION_SENT: 'Registration sent',
  WEBCOMRAD_PASSWORD_RESET_SENT_TITLE: 'Password Reset sent',
  OWNERSHIP: "'s",
  REFERRAL_SUBMIT_ERROR:
    'Your registration could not be submitted. Please check your internet connection and try again. Need support? Call us on 1300 147 852',
  WEBCOMRAD_PASSWORD_RESET_SUBMIT_ERROR:
    'Your form could not be submitted. Please check your internet connection and try again. Need support? Call us on 1300 147 852',
  REFERRAL_TITLE: 'Send e-Referral',
  REFERRAL_SUBTITLE:
    'This referral will be sent to both I-MED Radiology and the patient once all the steps have been completed and you have entered "submit". The patient will receive a text message, notifying them that they have been e-referred. If you leave without completing all required steps in this form, the e-referral will not be sent to I-MED or the patient.',
  REFERRAL_FORM_SUBTITLE: 'All fields must be completed before continuing.',
  REFERRAL_FORM_CONFIRM_AND_SEND: 'Please type your full name and confirm that you are entitled to make this request.',
  REFERRAL_MOBILE_INSTRUCTION: 'Patient will receive SMS with booking details',
  REFERRAL_IV_CONTRAST_INSTRUCTION:
    'Please indicate whether contrast allergy, renal disease or diabetes metformin treatment apply',
  REFERRAL_EMAIL_INSTRUCTION: 'Patient will be emailed a copy of the request',
  REFERRAL_EXAM_DESCRIPTION: 'Exam description',
  REFERRAL_CLINICAL_DESCRIPTION: 'Clinical description',
  REFERRAL_BEARING_AGE: 'If female is of child bearing age, please indicate if patient may be pregnant:',
  REFERRAL_CC_DOCTOR: 'Would you like to CC a doctor?',
  REFERRAL_EMAIL_SENT_TO: 'This form has been emailed to I-MED Radiology and the patient ',
  REFERRAL_SUCCESS_MESSAGE_1: 'An SMS will be sent shortly to ',
  REFERRAL_SUCCESS_MESSAGE_2: ' mobile number ',
  REFERRAL_SUCCESS_MESSAGE_3: ', with  instructions to book an appointment online.',
  REFERRAL_PATIENT_SMS_INSTRUCTIONS_1: 'An SMS will be sent shortly to ',
  REFERRAL_PATIENT_SMS_INSTRUCTIONS_2: ' mobile number, with instructions to book an appointment online.',
  REFERRAL_NAVIGATION_CONFIRMATION_TITLE: 'Are you sure?',
  REFERRAL_NAVIGATION_CONFIRMATION_1: 'If you leave now the ',
  REFERRAL_NAVIGATION_CONFIRMATION_2: ' e-referral will not be sent.',
  IV_CONTRAST_ALERT: 'IV Contrast Alert (optional)',
  CONTRAST_ALLERGY: 'Contrast Allergy:',
  RENAL_DISEASE: 'Renal Disease:',
  DIABETES_MEFORMIN_TREATMENT: 'Diabetes Metformin treatment:',
  DOCTORS_DETAILS: "Doctor's details",
  SIGNATURE: 'Signature',
  DATE: 'Date',
  DATE_PLACEHOLDER: 'dd/mm/yyyy',
  DR_PLACEHOLDER: 'Dr Kate Bishop',
  HEALTH_INSURANCE_ACT: 'I am entitled under the Health Insurance Act 1973 (Cth) to make this request',
  SEND_EMAIL_COPY: 'Send a copy of the e-referral to my email',
  SEND_PRACTICE_EMAIL_COPY: 'Send a copy of the e-referral to my practice email',
  PATIENT_NOTIFICATION: 'Do not send notification to the patient',
  EXAMINATION_AND_CLINICAL_DETAILS: 'Examination and clinical details',
  REFERRING_PRACTITIONER: 'Referring practitioner',
  CONFIRM_AND_SEND: 'Confirm and send',
  VIEW_REPORT: 'View report as PDF',
  BACK_TO_PATIENT_SEARCH: 'Back to patient search',
  BACK_TO_HOME: 'Back to Home',
  ERROR_TITLE: 'Something went wrong',
  ERROR_MESSAGE: 'We apologise for interrupting your flow. Please return to patient search to resume your session.',
  ERROR_MESSAGE_MOBILE: 'Must be a valid mobile number',
  NOTFOUND_MESSAGE: 'The page you have requested could not be found. ',
  NOTFOUND_TITLE: 'This page does not exist',
  RESTRICTED_ACCESS: 'Restricted access',
  RESTRICTED_EXPLAINATION:
    'You are requesting access to a patient’s records and are not the referring health professional on record. Access to this record will be recorded and misuse may result in immediate suspension of your account.',
  RESTRICTED_ACKNOWLEDGEMENT: 'By acknowledging and accepting  ',
  RESTRICTED_ACKNOWLEDGMENT_BOLD: 'you will be granted access',
  RESTRICTED_ACKNOWLEDGMENT_TIMEFRAME: '7 days.',
  RESTRICTED_CONSENT:
    'I acknowledge that I am accessing this patient’s records only for medical reasons and for the sole benefit of the patient. This patient would reasonably expect me to be accessing their records because I am part of their treating team or I have their express consent to do so.',
  RESTRICTED_ACCEPT:
    'I agree that I am accessing this patient’s record in accordance with these conditions and my obligations under the ',
  RESTRICTED_TERMS_OF_USE: 'Terms of Use',
  ACCESS_TIMEOUT: 'Break glass access has timed out',
  TIMEOUT_LINE1: 'To keep patient data secure break glass access for ',
  TIMEOUT_LINE2: ' has timed out.',
  TIMEOUT_SUMMARY:
    'To continue viewing their restricted access records you must accept the terms of confidentiality again.',
  TC_ACCEPTANCE_TERMS_OF_USE: 'Terms of Use',
  TC_ACCEPTANCE_ACCEPT_BTN: 'Accept and continue',
  TC_ACCESS_DENIED_MODAL_TITLE: 'Access denied',
  TC_ACCESS_DENIED_MODAL_DESC:
    'As you have not accepted the required Terms of Use, your access to I-MED Online is denied.',
  TC_ACCESS_DENIED_MODAL_REVIEW_TERMS: 'Review Terms of Use again',
  TC_ACCESS_GRANTED_MODAL_TITLE: 'Access granted',
  TC_ACCESS_GRANTED_MODAL_DESC: 'You have been granted access to patient records and your account is ready for use.',
  TC_ACCESS_GRANTED_MODAL_BTN: 'Start using I-MED Online',
  NOT_AVAILABLE: 'Not available',
  LOADING: 'Loading...',
  LOADING_RESULTS: 'Loading results...',
  EXTRA_STUDIES_LABEL_1: 'This patient has ',
  EXTRA_STUDIES_LABEL_2: ' completed outside of your practice network matching your search filters.',
  NO_EXTRA_STUDIES_FOUND_LABEL:
    'This patient has no studies completed outside of your practice network matching your search filters.',
  BREAK_GLASS_ACCESS_MESSAGE: 'Break glass to access these results',
  UPDATE_SEARCH_VIEW_ALL_STUDIES: 'Update search to view all studies',
  SESSION_TIMEOUT: 'Session timeout',
  MAINTENANCE_MODAL_TITLE: 'I-MED Online service disruption',
  MAINTENANCE_MODAL_MESSAGE_1:
    'Due to scheduled maintenance works, I-MED Online will be unavailable between <strong>10 pm and 11 pm</strong> on <strong>31 August.</strong>',
  MAINTENANCE_MODAL_MESSAGE_2: (link: string): string =>
    `During this time, you can access patient results via the <strong>I-MED InteleConnect EV</strong> using your <strong>existing I-MED Online login</strong> details via this link: <a href=${link} target="_blank">${link}</a>`,
  MAINTENANCE_MODAL_MESSAGE_3:
    'This portal will be offline on <strong>30 November</strong> from <strong>9:00pm – 11:00pm</strong> AEDT while we conduct scheduled maintenance. After this period, you may experience slower system performance. We apologise for any inconvenience and appreciate your patience.',
  MAINTENANCE_MODAL_MESSAGE_4:
    'We are currently experiencing an unexpected outage affecting access to I-MED Online. Our IT team is diligently working to identify the problem and resolve it as soon as possible. We apologise for any inconvenience during this time.',
}

export const accessIMOLookup = {
  dr_jones___partners: 'Dr Jones & Partners',
  access_i_med_online_2_0: 'Access I-MED Online 2.0',
  access_i_med_online: 'Access I-MED Online',
  apply_for_i_med_online_2_0: 'Apply for I-MED Online 2.0',
}

export default text
