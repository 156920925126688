import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { combineReducers, Reducer } from 'redux'
import { History } from 'history'
import loginReducer from './LoginReducer'
import registerAccountReducer from './RegisterAccountReducer'
import navReducer from './NavReducer'
import accountReducer from './AccountReducer'
import recoverUsernameReducer from './RecoverUsernameReducer'
import footerReducer from './FooterReducer'
import contactInfoReducer from './ContactInfoReducer'
import PasswordReducer from './PasswordReducer'
import addPracticeReducer from './AddPracticeReducer'
import ForgotPasswordReducer from './ForgotPasswordReducer'
import ResetPasswordReducer from './ResetPasswordReducer'
import PatientSearchReducer from './PatientSearchReducer'
import PatientReportReducer from './PatientReportReducer'
import patientDetailsReducer from './PatientDetailsReducer'
import PatientStudiesReducer from './PatientStudiesReducer'
import PatientImagesReducer from './PatientImagesReducer'
import ErrorBoundaryReducer from './ErrorBoundaryReducer'
import ReferralReducer from './ReferralReducer/ReferralReducer'
import AcceptTCReducer from './AcceptTCReducer'
import patientSearchStateReducer from './PatientSearchStateReducer'
import WebcomradReducer from './WebcomradReducer'
import WebcomradPasswordResetReducer from './WebcomradPasswordResetReducer'
import AppointmentSearchReducer from './AppointmentSearchReducer'

export default (history: History): Reducer =>
  combineReducers({
    router: createRouterReducer(history),
    login: loginReducer,
    recoverUsername: recoverUsernameReducer,
    registerAccount: registerAccountReducer,
    nav: navReducer,
    account: accountReducer,
    admin: footerReducer,
    contactInfo: contactInfoReducer,
    password: PasswordReducer,
    addPractice: addPracticeReducer,
    forgotPassword: ForgotPasswordReducer,
    resetPassword: ResetPasswordReducer,
    patientSearch: PatientSearchReducer,
    appointmentSearch: AppointmentSearchReducer,
    patientRowSearchState: patientSearchStateReducer,
    patientReport: PatientReportReducer,
    patientDetails: patientDetailsReducer,
    patientImages: PatientImagesReducer,
    patientStudies: PatientStudiesReducer,
    errorBoundary: ErrorBoundaryReducer,
    referral: ReferralReducer,
    acceptTC: AcceptTCReducer,
    webcomrad: WebcomradReducer,
    webcomradPasswordReset: WebcomradPasswordResetReducer,
  })
