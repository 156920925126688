import { FieldValue } from '../../../../actions/registerAccountActions.types'

export enum genericFieldNames {
  fullName = 'fullName',
  contactNumber = 'contactNumber',
  emailAddress = 'emailAddress',
}

export const genericValidators = (field: string, value: FieldValue): boolean => {
  switch (field) {
    case genericFieldNames.fullName:
      return /^[a-zA-Z ,.'-]*? [a-zA-Z ,.'-]*?$/.test(value.toString())
    case genericFieldNames.contactNumber:
      return /^\(?((0|\+61)([24378]))?\)?([ |-])?[0-9]{2}([ |-])?[0-9]{2}([ |-])?[0-9]([ |-])?[0-9]{3}$/.test(
        value.toString(),
      )
    case genericFieldNames.emailAddress:
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value.toString())
    default:
      return false
  }
}

export default genericValidators
