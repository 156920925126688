import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import SingleLineTextInputField from '../../../components/forms/fields/SingleLineTextInputField'
import text from '../../../_constants/text'
import DropdownSelect from '../../../components/forms/fields/DropdownSelect'
import CheckboxField from '../../../components/forms/fields/CheckboxField'
import InputLabel from '../../../components/atoms/InputLabel'
import ExternalLink from '../../../components/atoms/ExternalLink'
import {
  webcomradRequiredFields,
  WebcomradFormDataModel,
  WebcomradFormErrorModel,
} from '../../../reducers/WebcomradReducer/WebcomradReducer'
import { webcomradFieldNames } from '../../../components/forms/validations/Webcomrad'

interface WebcomradFormModel {
  formData: WebcomradFormDataModel
  errors: WebcomradFormErrorModel
  onValueChange: (fieldName: string, value: string | boolean) => void
}

const WebcomradForm: FC<WebcomradFormModel> = ({ formData, errors, onValueChange }) => {
  return (
    <div data-testid="webcomrad-form">
      <Row>
        <Col xs={12} lg={10}>
          <p data-testid="t-and-cs-message">
            {text.WEBCOMRAD_T_AND_CS_MESSAGE}{' '}
            <ExternalLink href={text.USER_ACCESS_AGREEMENT_LINK}>{text.USER_ACCESS_AGREEMENT_LINK}</ExternalLink>
          </p>
          <CheckboxField
            label={`${text.YES}${text.I_ACCEPT}${text.USER_ACCESS_AGREEMENT}`}
            inputName={webcomradFieldNames.termsAndConditions}
            value={formData.termsAndConditions}
            onChangeCb={onValueChange}
            testId="t-and-cs-checkbox"
            required={webcomradRequiredFields.termsAndConditions}
            error={errors.termsAndConditions}
            errorMessage={text.FIELD_REQUIRED}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.FULL_NAME}
            inputName={webcomradFieldNames.fullName}
            required={webcomradRequiredFields.fullName}
            value={formData.fullName}
            errorMessage={`${text.FULL_NAME_ERROR_MESSAGE} ${text.PLACEHOLDER_PATIENT_NAME}`}
            instructionMessage={text.FULL_NAME_INSTRUCTION}
            onChangeCb={onValueChange}
            error={errors.fullName}
            placeholder={text.PLACEHOLDER_PATIENT_NAME}
            labelTestId="full-name-label"
            inputTestId="full-name-input"
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.EMAIL}
            inputName={webcomradFieldNames.referrerEmail}
            required={webcomradRequiredFields.referrerEmail}
            value={formData.referrerEmail}
            instructionMessage={text.REFERRER_EMAIL_INSTRUCTION}
            onChangeCb={onValueChange}
            error={errors.referrerEmail}
            errorMessage={`${text.EMAIL_ERROR_MESSAGE} ${text.PLACEHOLDER_PATIENT_EMAIL}`}
            placeholder={text.PLACEHOLDER_PATIENT_EMAIL}
            labelTestId="referrer-email-label"
            inputTestId="referrer-email-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.MOBILE_NUMBER}
            inputName={webcomradFieldNames.mobileNumber}
            required={webcomradRequiredFields.mobileNumber}
            value={formData.mobileNumber}
            errorMessage={`${text.MOBILE_NUMBER_ERROR_MESSAGE} ${text.PLACEHOLDER_MOBILE}`}
            onChangeCb={onValueChange}
            error={errors.mobileNumber}
            placeholder={text.PLACEHOLDER_MOBILE}
            labelTestId="mobile-number-label"
            inputTestId="mobile-number-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PROVIDER_NUMBER}
            inputName={webcomradFieldNames.providerNumber}
            required={webcomradRequiredFields.providerNumber}
            value={formData.providerNumber}
            errorMessage={`${text.PROVIDER_NUMBER_ERROR_MESSAGE} ${text.PRACTICE_NUMBER_PLACEHOLDER}`}
            onChangeCb={onValueChange}
            error={errors.providerNumber}
            placeholder={text.PRACTICE_NUMBER_PLACEHOLDER}
            labelTestId="provider-number-label"
            inputTestId="provider-number-input"
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.AHPRA}
            inputName={webcomradFieldNames.ahpranumber}
            required={webcomradRequiredFields.ahpranumber}
            value={formData.ahpranumber}
            onChangeCb={onValueChange}
            error={errors.ahpranumber}
            errorMessage={`${text.AHPRA_ERROR_MESSAGE} ${text.AHPRA_NUMBER_PLACEHOLDER}`}
            placeholder={text.AHPRA_NUMBER_PLACEHOLDER}
            labelTestId="AHPRA-number-label"
            inputTestId="AHPRA-number-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PRACTICE_NAME}
            inputName={webcomradFieldNames.practiceName}
            required={webcomradRequiredFields.practiceName}
            value={formData.practiceName}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.practiceName}
            placeholder={text.PRACTICE_NAME_PLACEHOLDER}
            labelTestId="practice-name-label"
            inputTestId="practice-name-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.DEPARTMENT_OR_WARD_LABEL}
            inputName={webcomradFieldNames.department}
            required={webcomradRequiredFields.department}
            value={formData.department}
            errorMessage={text.DEPARTMENT_ERROR_MESSAGE}
            onChangeCb={onValueChange}
            error={errors.department}
            labelTestId="department-label"
            inputTestId="department-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={12}>
          <SingleLineTextInputField
            label={text.PRACTICE_ADDRESS}
            inputName={webcomradFieldNames.practiceAddress}
            required={webcomradRequiredFields.practiceAddress}
            value={formData.practiceAddress}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.practiceAddress}
            placeholder={text.PRACTICE_ADDRESS_PLACEHOLDER}
            labelTestId="practice-address-label"
            inputTestId="practice-address-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PRACTICE_SUBURB}
            inputName={webcomradFieldNames.practiceSuburb}
            required={webcomradRequiredFields.practiceSuburb}
            value={formData.practiceSuburb}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.practiceSuburb}
            placeholder={text.PRACTICE_SUBURB_PLACEHOLDER}
            labelTestId="practice-suburb-label"
            inputTestId="practice-suburb-input"
          />
        </Col>
        <Col xs={12} lg={3}>
          <DropdownSelect
            label={text.STATE}
            inputName={webcomradFieldNames.practiceState}
            required={webcomradRequiredFields.practiceState}
            value={formData.practiceState}
            onChangeCb={onValueChange}
            error={errors.practiceState}
            errorMessage={text.FIELD_REQUIRED}
            options={text.STATES}
            testId="practice-state-dropdown"
          />
        </Col>
        <Col xs={12} lg={3}>
          <SingleLineTextInputField
            label={text.POSTCODE}
            inputName={webcomradFieldNames.practicePostcode}
            required={webcomradRequiredFields.practicePostcode}
            value={formData.practicePostcode}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.practicePostcode}
            placeholder={text.PRACTICE_POSTCODE_PLACEHOLDER}
            labelTestId="practice-postcode-label"
            inputTestId="practice-postcode-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PRACTICE_PHONE}
            inputName={webcomradFieldNames.practicePhone}
            required={webcomradRequiredFields.practicePhone}
            value={formData.practicePhone}
            errorMessage={`${text.PHONE_NUMBER_ERROR_MESSAGE} ${text.PRACTICE_PHONE_PLACEHOLDER}`}
            onChangeCb={onValueChange}
            error={errors.practicePhone}
            placeholder={text.PRACTICE_PHONE_PLACEHOLDER}
            labelTestId="practice-phone-label"
            inputTestId="practice-phone-input"
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PRACTICE_FAX}
            inputName={webcomradFieldNames.practiceFax}
            required={webcomradRequiredFields.practiceFax}
            value={formData.practiceFax}
            errorMessage={`${text.FAX_ERROR_MESSAGE} ${text.PRACTICE_PHONE_PLACEHOLDER}`}
            onChangeCb={onValueChange}
            error={errors.practiceFax}
            placeholder={text.PRACTICE_PHONE_PLACEHOLDER}
            labelTestId="practice-fax-label"
            inputTestId="practice-fax-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={5}>
          <DropdownSelect
            label={text.SPECIALITY_LABEL}
            inputName={webcomradFieldNames.speciality}
            required={webcomradRequiredFields.speciality}
            value={formData.speciality}
            onChangeCb={onValueChange}
            error={errors.speciality}
            errorMessage={text.FIELD_REQUIRED}
            options={text.SPECIALITY_OPTIONS}
            testId="speciality-dropdown"
          />
        </Col>
      </Row>
      {formData.speciality === 'Other' && (
        <Row>
          <Col xs={12} lg={6}>
            <SingleLineTextInputField
              label={text.OTHER_SPECIALITY_LABEL}
              inputName={webcomradFieldNames.otherSpeciality}
              required={webcomradRequiredFields.otherSpeciality}
              value={formData.otherSpeciality}
              errorMessage={text.OTHER_SPECIALITY_ERROR_MESSAGE}
              onChangeCb={onValueChange}
              error={errors.otherSpeciality}
              labelTestId="speciality-other-label"
              inputTestId="speciality-other-input"
            />
          </Col>
        </Row>
      )}
      <div className="d-flex flex-column mb-4">
        <InputLabel label={`${text.OPERATING_SYSTEM_LABEL} *`} />
        <CheckboxField
          className="mb-2"
          label={text.OPERATING_SYSTEM_MAC_LABEL}
          required={false}
          inputName={webcomradFieldNames.operatingSystemMac}
          onChangeCb={onValueChange}
          value={formData.operatingSystemMac}
          error={errors.operatingSystemMac && errors.operatingSystemPC}
          testId="operating-system-mac"
        />
        <CheckboxField
          className="mb-2"
          label={text.OPERATING_SYSTEM_PC_LABEL}
          required={false}
          inputName={webcomradFieldNames.operatingSystemPC}
          onChangeCb={onValueChange}
          value={formData.operatingSystemPC}
          error={errors.operatingSystemMac && errors.operatingSystemPC}
          testId="operating-system-pc"
        />
        {errors.operatingSystemMac && errors.operatingSystemPC && (
          <Row className="m-0 pl-3" data-testid="operating-system-error-message">
            <p className="error">{text.OPERATING_SYSTEM_ERROR_MESSAGE}</p>
          </Row>
        )}
      </div>
    </div>
  )
}

export default WebcomradForm
