import React from 'react'
import Icon from '../../../atoms/Icon'
import icons from '../../../../_constants/icons'
import text from '../../../../_constants/text'

interface PasswordIndicatorProps {
  password: string
}

const PasswordIndicator: React.FC<PasswordIndicatorProps> = ({ password }) => {
  const passwordValidator = (password: string) => {
    return {
      number: /\d/.test(password),
      symbol: /[^a-zA-Z0-9]+/g.test(password),
      length: password.length >= 8,
    }
  }

  return (
    <>
      <div className="password-indicator d-flex flex-row mt-3">
        <Icon
          name={passwordValidator(password).number ? icons.INDICATOR_LIGHT : icons.INDICATOR}
          className="password-indicator-icon"
        />
        <p className={passwordValidator(password).number ? 'light' : 'regular'}>{text.PASSWORD_REQUIREMENT_NUMBER}</p>
      </div>
      <div className="password-indicator d-flex flex-row">
        <Icon
          name={passwordValidator(password).symbol ? icons.INDICATOR_LIGHT : icons.INDICATOR}
          className="password-indicator-icon"
        />
        <p className={passwordValidator(password).symbol ? 'light' : 'regular'}>{text.PASSWORD_REQUIREMENT_SYMBOL}</p>
      </div>
      <div className="password-indicator d-flex flex-row">
        <Icon
          name={passwordValidator(password).length ? icons.INDICATOR_LIGHT : icons.INDICATOR}
          className="password-indicator-icon"
        />
        <p className={passwordValidator(password).length ? 'light' : 'regular'}>{text.PASSWORD_REQUIREMENT_LENGTH}</p>
      </div>
    </>
  )
}

export default PasswordIndicator
