export const REFRESH_FREQUENCY_SECONDS = 60 * 60 // 1 hour

export enum QUERY_DEPTH {
  DEFAULT = 1,
  MEDIUM = 2,
  MAX = 3,
}

export enum OG_TYPES {
  WEBSITE = 'website',
  ARTICLE = 'article',
  PROFILE = 'profile',
}

export const LOCAL_TOKEN_KEY = 'token'
export const LOCAL_REFRESH_KEY = 'refresh'
export const LOCAL_DEFAULT_PATIENT_SEARCH_KEY_PREFIX = 'patientSearch.default.'
export const MAX_CRM_COUNT = 100

export enum HttpStatusCodes {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  API_ERROR = 405,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
}

export enum UrlParams {
  BREAK_GLASS = 'breakGlass',
  ACCESSION_NUMBER = 'accessionNo',
}

export const TOKEN_TYPE = 'Bearer'
