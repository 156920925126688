export enum FieldValueType {
  fullName = 'fullName',
  state = 'state',
  email = 'email',
  mobile = 'mobile',
  ahpraNumber = 'ahpraNumber',
  practices = 'practices',
  password = 'password',
  providerNumber = 'providerNumber',
}

export const validateString = (valueType: string, required: boolean, value: string): boolean => {
  if (!required && !value) {
    return true
  }
  if (required && !value) {
    return false
  }
  switch (valueType as FieldValueType) {
    case FieldValueType.mobile:
      return /^0[23478][0-9]{8}$/.test(value)
    case FieldValueType.email:
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)
    case FieldValueType.fullName:
      return /^[a-zA-Z\ ,.'-]*?\ [a-zA-Z\ ,.'-]*?$/.test(value)
    case FieldValueType.ahpraNumber:
      return /^[A-Z]{3}[a-zA-Z0-9]{10}$/.test(value)
    case FieldValueType.password:
      return (
        value.length >= 8 &&
        /\d/.test(value) &&
        /[a-zA-Z0-9]+/g.test(value) &&
        /[!@#$%^&*(),.?":{}|<>\-_=+\[\]\\';/~`]/g.test(value)
      )
    case FieldValueType.providerNumber:
      return value.length === 8
    default:
      return true
  }
}

export const validateBool = (required: boolean, value: boolean): boolean => {
  if (required && !value) {
    return false
  }
  return true
}
